import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// icons
import PeopleIcon from "@mui/icons-material/People";
import SpeedIcon from "@mui/icons-material/Speed";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from '@mui/icons-material/Assignment';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorIcon from '@mui/icons-material/Warning';
import NotificationIcon from '@mui/icons-material/Notifications';

import { NavLink as RouterLink } from "react-router-dom";
import ProtectedContent from "../protectedContent/ProtectedContent";
import { Typography } from "@mui/material";

interface Props {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 240,
    },
    toolbar: theme.mixins.toolbar,
    logo: {
      width: "calc(100% - 40px)",
      padding: "30px 20px",
      paddingRight: "16px",
      position: "relative"
    },
    link: {
      color: "#fff",
      width: "calc(100% + 10px)",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
      "& .MuiListItemIcon-root": {
        color: "#fff",
      },
    },
    activeLink: {
      backgroundColor: theme.palette.primary.dark,
      width: "calc(100% + 10px)",
      "& .MuiListItemIcon-root": {
        color: theme.palette.secondary.main,
      },
    },
    footNote: {
      position: "absolute",
      bottom: 0,
      textAlign: "center",
      paddingBottom: 20,
      width: "100%",
      "& a": {
        color: "white",
        display: "block",
        opacity: ".8",
        textDecoration: "none"
      }
    }
  })
);

const Navigation: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => props.toggleDrawer(true)}
      onKeyDown={() => props.toggleDrawer(false)}
    >
      <div className={classes.toolbar}>
        <RouterLink to="/">
          <img src="/assets/img/logo.svg" className={classes.logo} />
        </RouterLink>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          component={RouterLink}
          to="/"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <SpeedIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/interfaces"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary={"Interfaces"} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/sync-history"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <SyncIcon />
          </ListItemIcon>
          <ListItemText primary={"Sync history"} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/error-log"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <ErrorIcon />
          </ListItemIcon>
          <ListItemText primary={"Error log"} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/notifications"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <NotificationIcon />
          </ListItemIcon>
          <ListItemText primary={"Notifications"} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/lookup-tables"
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={"Lookup tables"} />
        </ListItem>
        <ProtectedContent countClientAdministratorAsAdmin>
          <ListItem
            button
            component={RouterLink}
            to="/user-management"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={"User management"} />
          </ListItem>
        </ProtectedContent>
      </List>
      <div className={classes.footNote}>
        <a href="https://www.chili.ch" target="_blank"><img src="/assets/img/icon_chili.svg" /><Typography variant="body2" display="inline-block" marginLeft={1} sx={{verticalAlign: "super"}}>Powered by Chili Digital AG</Typography></a>
      </div>
    </div>
  );
};

export default Navigation;
